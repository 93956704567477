.appBar {
    border-bottom: 1px solid var(--devPaletteDivider);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
@media only screen and (min-width: 1920px) {
    .appBar {
        border-radius: 0 0 8px 8px;
        overflow: hidden;
    }
}

.toolbar {
    padding-right: 0;
    background-color: var(--devPaletteBackgroundPaper);
    height: 68px;
}

.toolbarTitle {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    padding-right: 0;
    font-size: 0.85rem;
    text-transform: uppercase;
}

.menuItem {
    padding: 0 var(--devSpacing1);
    width: fit-content;
    min-width: 60px;
}

.toolbarSubtitleContainer {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
}

.toolbarSubtitle {
    opacity: 0.5;
}

.toolbarNavigation {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
}

.link {
    margin: var(--devSpacing1);
}

.helpOverlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-color: var(--devPaletteSecondaryMain);
    z-index: 99;
    top: 0;
    left: 0;
}

.userIcon {
    height: 100%;
}

.logoutButton {
    margin: 0 0 0 var(--devSpacing1);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.currentUser {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    font-family: var(--devTypographySubtitle1FontFamily);
}
