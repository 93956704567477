.exit {
    width: fit-content;
    background-color: var(--devPaletteSecondaryMain);
    color: var(--devPaletteSecondaryContrastText);
    padding: var(--devSpacing1) var(--devSpacing2);
    border-radius: 0 0 var(--devSpacing2) var(--devSpacing2);
    position: absolute;
    left: calc(100% - 8px);
    top: 100%;
    transform: translateX(-100%);
    cursor: pointer;
    box-shadow: inset 0 36px 16px -30px #000000cc;
}
