.border {
    border: solid 1px var(--devPaletteBackgroundDefault);
}

.container {
    height: 100%;
}

.header {
    background: var(--devPaletteBackgroundDefault);
    padding: 0;
}

.dialog {
    margin: 0;
    width: 90%;
    height: 90%;
    max-width: 1000px;
    max-height: 800px;
}

.widePopup {
    margin: 0;
    width: 90%;
    height: 90%;
    max-width: 1000px;
    max-height: 800px;
}

/* --devBreakpointsValuesMd media queries does not support vars*/
@media only screen and (max-width: 960px) {
    .dialog,
    .widePopup {
        width: 100%;
        height: 100%;
        margin: 0;
        max-width: 100%;
        max-height: none;
        border-radius: 0;
    }
}
