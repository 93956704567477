.App {
    text-align: center;
    max-width: 1920px;
    margin: auto;
}

.MuiDataGrid-main {
    color: var(--devPaletteTextPrimary);
}

.MuiDataGridPanel-root > .MuiPaper-root {
    background-color: var(--devPaletteBackgroundPaper);
}

.MuiDataGrid-selectedRowCount {
    color: var(--devPaletteTextPrimary);
}

.bubble {
    background-color: var(--devPalettePrimaryMain);
    position: absolute;
    display: flex;
    justify-content: center;
    color: cornsilk;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    z-index: 9999999;
}

.bubble::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
    z-index: 9999999;
}

.cornerLeft {
    border-radius: 8px 0 0 8px;
}
.cornerLeft::before {
    right: -20px;
    border: 20px solid transparent;
    border-right: none;
    border-left-color: var(--devPalettePrimaryMain);
}

.cornerRight {
    border-radius: 0 8px 8px 0;
}

.cornerRight::before {
    left: -20px;
    border: 20px solid transparent;
    border-left: none;
    border-right-color: var(--devPalettePrimaryMain);
}

.cornerTop {
    border-radius: 8px 8px 0 0;
}
.cornerTop::before {
    left: 0;
    bottom: -20px;
    border: 20px solid transparent;
    border-bottom: none;
    border-top-color: var(--devPalettePrimaryMain);
}

.cornerBottom {
    border-radius: 0 0 8px 8px;
}

.cornerBottom::before {
    bottom: 100%;
    left: 0;
    border: 20px solid transparent;
    border-top: none;
    border-bottom-color: var(--devPalettePrimaryMain);
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.react-swipeable-view-container {
    height: 100%;
}

.svgIcon {
    width: 1em;
    height: 1em;
    fill: currentColor;
}

*::-webkit-scrollbar {
    width: var(--devSpacing1);
    height: var(--devSpacing1);
    border-radius: 20px;
}

*::-webkit-scrollbar-track {
    background: var(--devPaletteBackgroundPaper);
    border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--devPaletteTextSecondary);
    border-radius: 20px;
    border: 3px solid transparent;
}

*::-webkit-scrollbar-corner {
    background-color: var(--devPaletteBackgroundPaper);
}

body {
    background-color: var(--devPaletteBackgroundDefault);
}
#ReviewTarget_header > div.MuiCardHeader-content {
    width: calc(100% - 92px);
}

.MuiPickersCalendarHeader-switchHeader
    .MuiPickersCalendarHeader-transitionContainer {
    width: fit-content;
}

.MuiPickersCalendarHeader-switchHeader p {
    position: unset;
}

.MuiPickersCalendarHeader-switchHeader {
    justify-content: center;
}
