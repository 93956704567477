.formPanel,
.infoPanel {
    height: 100vh;
    overflow: auto;
}

.infoPanel {
    color: white;
    font-weight: 900;
    text-transform: uppercase;
}

.formPanel {
    background-color: var(--devPaletteBackgroundPaper);
    box-shadow: 0 0 29px -4px rgba(0, 0, 0, 0.7);
}

.formPanelContainer {
    width: 100%;
    padding: var(--devSpacing4);
    margin: auto;
}

.formPanelContainer button,
.title {
    margin: var(--devSpacing2) var(--devSpacing1);
}

.title .separator {
    margin: 0 var(--devSpacing1);
}

.title h3 {
    color: var(--devPaletteTextDisabled);
    cursor: pointer;
}

.title .active {
    color: var(--devPaletteTextPrimary);
}

.autocomplete div {
    top: unset;
}

.version {
    position: absolute;
    padding: var(--devSpacing2);
    cursor: pointer;
    top: 0;
    left: 0;
}

.embed {
    background: var(--devPaletteBackgroundPaper);
    width: calc(100% - var(--devSpacing1));
    height: 100%;
    padding: var(--devSpacing1);
}
