.nodata {
    width: 100%;
    height: 100%;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--devPaletteTextDisabled);
    font-size: var(--devTypographyH5FontSize);
    font-weight: var(--devTypographyH5FontWeight);
}
