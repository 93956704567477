.targetContainer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
}

.targetAndArrowsContainer {
    height: 100%;
    width: 100%;
    position: relative;
}

.target {
    height: 100%;
    width: 100%;
    text-rendering: geometricPrecision;
}
