.container {
    height: 100%;
}

.sidebar {
    height: inherit;
}

.licencesView {
    padding: var(--devSpacing1);
    border-left: 1px solid var(--devPaletteActionDisabled);
    height: 100%;
    overflow: auto;
}

.listItem {
    padding: var(--devSpacing1) var(--devSpacing3);
}

.selected {
    background-color: var(--devPalettePrimaryMain);
    color: var(--devPalettePrimaryContrastText);
}

.selected:hover {
    background-color: var(--devPalettePrimaryDark);
}

.overviewLogoContainer {
    background: var(--devPalettePrimaryMain);
    padding: var(--devSpacing2);
    height: 120px;
    width: 100%;
}

.overviewLogoContainer svg {
    height: 100%;
    width: 100%;
}
