.container {
    height: 100%;
    width: 100%;
    display: grid;
    justify-content: space-around;
}

.overviewLogoContainer {
    background: var(--devPalettePrimaryMain);
    padding: var(--devSpacing2);
    height: 120px;
    width: 100%;
}

.overviewLogoContainer svg {
    height: 100%;
    width: 100%;
}

.bodyText {
    text-align: center;
}

.qrCode {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
