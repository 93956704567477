.treeItem {
    padding: var(--devSpacing2) var(--devSpacing1);
}

.selected {
    background-color: var(--devPalettePrimaryMain);
    color: var(--devPaletteBackgroundDefault);
}

.treeView {
    width: 100%;
}

.treeView ul {
    margin-left: var(--devSpacing1);
}
