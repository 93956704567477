.targetContainer {
    width: 100%;
    height: 100%;
}

.spectatorContainer {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: var(--devPaletteBackgroundDefault);
}

.targetsContainer {
    height: 67%;
}

.oneTargetContainer {
    height: 50%;
}

.userTableContainer {
    transition-duration: 200ms;
    height: 33%;
    overflow: hidden;
}

.fullUserTableContainer {
    transition-duration: 200ms;
    height: 100%;
    overflow: auto;
}

.spectateTargetContainer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.spectateTargetContainer *::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.targetContainer span,
.targetContainer button {
    opacity: 0;
}

.targetContainer:hover span,
.targetContainer:hover button {
    opacity: 1;
}

.statsControl {
    position: absolute;
    top: var(--devSpacing6);
    left: var(--devSpacing1);
    z-index: 3;
}

.stats {
    padding: var(--devSpacing1);
    height: 100%;
    text-align: left;
}

.firePoint {
    background-color: var(--devPalettePrimaryDark);
    color: var(--devPalettePrimaryContrastText);
    position: absolute;
    top: var(--devSpacing1);
    right: var(--devSpacing1);
}

.shootersInfo {
    background-color: var(--devPalettePrimaryDark);
    color: var(--devPalettePrimaryContrastText);
    position: absolute;
    top: var(--devSpacing1);
    left: var(--devSpacing1);
    width: fit-content;
    padding: var(--devSpacing1);
    border-radius: var(--devSpacing1);
}

.shootersInfo p {
    margin-left: var(--devSpacing1);
}

.addRow {
    background-color: var(--devPaletteInfoMain);
    width: 100%;
}

.buttonBase {
    width: 100%;
}

.disabled {
    color: var(--devPaletteActionDisabled);
    opacity: 0.4;
}

.error {
    color: var(--devPaletteErrorDark);
}

.placeholderForDevice {
    background-color: var(--devPaletteBackgroundDefault);
    height: 100%;
}

.placeholderForDevice h4 {
    opacity: 0.2;
}

.accent {
    background-color: var(--devPaletteSecondaryMain);
    color: var(--devPaletteSecondaryContrastText);
}

@keyframes blink1 {
    from {
        background: var(--devPaletteSecondaryMain);
    }
    to {
        background: none;
    }
}

@keyframes blink2 {
    from {
        background: var(--devPaletteSecondaryMain);
    }
    to {
        background: none;
    }
}

@keyframes blink3 {
    from {
        background: var(--devPaletteSecondaryLight);
    }
    to {
        background: none;
    }
}

.lastHit1 {
    animation: blink1 1s ease-in-out 0s;
}

.lastHit2 {
    animation: blink2 1s ease-in-out 0s;
}

.help {
    animation: blink3 1s ease-in-out infinite 0s;
}
