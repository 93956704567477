.zoomControl {
    z-index: 1;
    opacity: 0.6;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--devPaletteBackgroundPaper);
}

.controlButton {
    padding: var(--devSpacing2);
}

.selectButton {
    padding: 0;
}

.zoomControl:hover {
    opacity: 1;
}
