.managementToolbar {
    padding-left: var(--devSpacing3);
    height: fit-content;
}

.weaponManagementInput {
    margin: var(--devSpacing1) 0;
}

.toolbarRoot {
    padding: 0 var(--devSpacing1) 0 var(--devSpacing2);
}

.highlightDark {
    color: var(--devPaletteSecondaryMain);
    background-color: var(--devPaletteSecondaryLight);
}

.highlightLight {
    background-color: var(--devPaletteSecondaryDark);
    color: var(--devPaletteTextPrimary);
}

.title {
    flex: 1 1 100%;
}

.container {
    padding: var(--devSpacing1);
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.contentContainer {
    margin: var(--devSpacing2);
}

.buttonContainer button {
    margin: 0 var(--devSpacing1);
}

.redButton {
    background-color: var(--devPaletteErrorMain);
    color: var(--devPaletteErrorContrastText);
}
