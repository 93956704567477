.heartbeat {
    animation: heartbeat 1.5s ease-in-out infinite both;
}

.hitHeartbeat {
    animation: hitHeartbeat 1.5s ease-in-out infinite both;
}
@keyframes hitHeartbeat {
    from {
        opacity: 1;
    }
    10% {
        opacity: 0.4;
    }
    17% {
        opacity: 0.7;
    }
    33% {
        opacity: 0.4;
    }
    45% {
        opacity: 1;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
