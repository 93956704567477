/* Porting https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.css */

.h-100 {
    height: 100% !important;
}

.d-flex {
    display: flex !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.align-self-end {
    align-self: flex-end !important;
}
