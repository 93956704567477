.padding {
    padding-left: var(--devSpacing2);
}

.margin {
    margin-left: var(--devSpacing2);
}

.error {
    color: var(--devPaletteErrorMain);
}

.warning {
    color: var(--devPaletteWarningMain);
}

.tableCell {
    padding: 0 var(--devSpacing2);
}

.tableContainer {
    width: 100%;
    height: calc(100% - 40px);
}

.table {
    height: 100%;
}

.tableContainer tr:nth-child(odd) {
    background-color: var(--devPaletteActionHover);
}

.selected td {
    background-color: var(--devPalettePrimaryLight) !important;
    color: var(--devPalettePrimaryContrastText);
}

.headerCell {
    padding: var(--devSpacing05);
}

.seriesRow {
    position: relative;
}

.tableRow {
    cursor: pointer;
}

.seriesRowBackground {
    background-color: var(--devPalettePrimaryDark);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    opacity: 0.1;
}

.scrollFix {
    margin-bottom: 48px;
}
