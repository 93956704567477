.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.select {
    max-height: 300px;
    overflow: auto;
    flex-basis: 100%;
}

.warning {
    background: var(--devPaletteWarningMain);
}

.textPrimary {
    color: var(--devPaletteTextPrimary);
}

.textSecondary {
    color: var(--devPaletteTextSecondary);
}

.textError {
    color: var(--devPaletteErrorMain);
}

.textWarning {
    color: var(--devPaletteWarningMain);
}

.textDisabled {
    color: var(--devPaletteActionDisabled);
}

.form {
    width: 500px;
}

.form > * {
    margin: var(--devSpacing1);
}

.hidden {
    display: none;
}

.pl1 {
    padding-left: var(--devSpacing1);
}

.pr1 {
    padding-right: var(--devSpacing1);
}
