.dialog {
    width: 100%;
    height: 100%;
    padding: 0 var(--devSpacing2);
    overflow: scroll;
}

.dialog > * {
    margin: var(--devSpacing1);
}

.monitorName {
    width: 25%;
}
