.commentHead {
    background-color: var(--devPalettePrimaryDark);
    color: var(--devPalettePrimaryContrastText);
}

.commentContent {
    width: 70vw;
    max-width: 700px;
    padding: var(--devSpacing4) var(--devSpacing1);
}

.sessionCommentButton {
    padding: 0;
}
