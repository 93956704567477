.zoomController {
    stroke-width: 1;
    fill: transparent;
    transition-duration: 0.3s;
}

.targetCorner {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 var(--devSpacing10) var(--devSpacing10) 0;
    border-color: transparent var(--devPalettePrimaryMain) transparent
        transparent;
    right: 0;
    top: 40px;
    position: absolute;
    z-index: 1;
}

.targetContainer {
    height: 100%;
    width: 100%;
    touch-action: auto;
    overflow: scroll;
    scroll-behavior: smooth;
    margin: auto;
}

.targetWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
}

.targetSVG {
    position: absolute;
}

.targetSVG .customImage {
    height: 100%;
    width: 100%;
}

.targetArrow {
    fill: var(--devPalettePrimaryLight);
    stroke-linejoin: round;
    stroke: white;
}

.mpi {
    fill: var(--devPaletteSecondaryMain);
}

.hit {
    cursor: pointer;
    stroke-opacity: 0.3;
}

.aura {
    cursor: pointer;
    fill-opacity: 0.3;
}

.selected {
    filter: invert(1);
}

.latest {
    filter: hue-rotate(90deg);
}
