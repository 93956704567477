body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

svg.lg {
    top: 0.325em;
    height: 1.5em !important;
    width: 1.5em !important;
}

svg.icon {
    height: 1.5em;
    width: 1.5em;
    position: relative;
    fill: currentColor;
}
