:root {
    --death-color-code: #1f67b3;
}

.errorContainer {
    width: 100vw;
    height: 100vh;
    background-color: var(--death-color-code);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;
}

.errorContainer h1 {
    font-size: 15vh;
    margin: 0;
}

.errorContainer h4 {
    font-size: 4vh;
    margin: 0;
}

.errorContainer h2 {
    color: var(--death-color-code);
}

.errorContainer button {
    margin: 8px;
    margin-top: 3vh;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    border: none;
    height: fit-content;
    background-color: white;
}

.errorContainer button:nth-child(odd) {
    border: 1px solid white;
    color: white;
    background-color: transparent;
}

.errorContainer button:focus {
    outline: none;
}

.sideNavigation {
    height: 100vh;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.5s;
    text-align: start;
}

.sideNavigation .closeButton {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 5vh;
    margin-left: 50px;
    cursor: pointer;
}

.logButton {
    font-size: 4vh;
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 8px;
}

.log {
    margin-bottom: 30vh;
}

.purple {
    color: mediumpurple;
}

.component {
    color: darkorange;
}
