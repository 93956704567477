.managementToolbar {
    padding-left: var(--devSpacing3);
}

.container {
    padding: var(--devSpacing1);
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.userManagementInput {
    margin: var(--devSpacing1) 0;
}

.toolbarRoot {
    padding: 0 var(--devSpacing1) 0 var(--devSpacing2);
}

.highlightDark {
    color: var(--devPaletteSecondaryMain);
    background-color: var(--devPaletteSecondaryLight);
}

.highlightLight {
    background-color: var(--devPaletteSecondaryDark);
    color: var(--devPaletteTextPrimary);
}

.title {
    flex: 1 1 100%;
}

.logoutButton {
    margin: 0 0 0 var(--devSpacing1);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInfo {
    width: 60px;
}

.currentUser {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    font-family: var(--devTypographySubtitle1FontFamily);
}

.currentDevice {
    text-align: center;
    overflow: hidden;
}

.table {
    height: calc(100% - 64px);
    overflow: auto;
}
