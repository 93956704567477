.dialogContainer {
    max-height: 100%;
    overflow: auto;
}

.optionsContainer {
    margin: 24px;
    height: 450px;
}

.formControl {
    margin: 16px 0 0 0;
}
