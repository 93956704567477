.exportCard {
    margin: var(--devSpacing2);
    width: 80%;
}

.exportContent {
    text-align: left;
    padding: var(--devSpacing2);
    width: auto;
}

.exportIcon {
    width: 100%;
    height: 100%;
}

.exportIconContainer {
    font-size: var(--devSpacing8);
    margin-left: auto;
    width: var(--devSpacing10);
    background-color: var(--devPalettePrimaryMain);
}

.exportUsbIconContainer {
    background-color: var(--devPaletteBackgroundDefault);
    width: calc(var(--devSpacing1) * 12);
}

.exportRefresh {
    margin: var(--devSpacing2);
}

.titleSpacing {
    font-weight: bold;
    margin-top: var(--devSpacing3);
    margin-bottom: var(--devSpacing1);
}

.title {
    width: 100%;
    border-bottom: 1px solid;
}

.description {
    opacity: 0.7;
}
