.navigationContainer {
    width: 300px;
    max-width: 100vh;
    position: relative;
}

.userContainer {
    width: auto;
    flex: 1;
}

.closeIcon {
    height: fit-content;
    width: fit-content;
    padding: var(--devSpacing05) var(--devSpacing05) var(--devSpacing2)
        var(--devSpacing2);
    z-index: 1;
    margin-left: auto;
    border-bottom-left-radius: 100px;
    background-color: var(--devPalettePrimaryMain);
    overflow: hidden;
    color: var(--devPalettePrimaryContrastText);
    position: sticky;
    top: 0;
}

.navigationItem {
    background-color: var(--devPalettePrimaryDark);
}

.navigationItem:hover {
    background-color: var(--devPalettePrimaryMain);
}

.mapperRow {
    padding: var(--devSpacing1);
    width: 100vw;
}

.mapperRow:hover {
    background-color: var(--devPaletteActionHover);
}

.mapperInput {
    margin-right: var(--devSpacing5);
}

.actions {
    background-color: var(--devPaletteBackgroundDefault);
}

.listSubheader {
    background-color: var(--devPaletteBackgroundDefault);
}

.closeButton {
    background-color: var(--devPaletteSecondaryMain);
    color: var(--devPaletteSecondaryContrastText);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0 0 0 16px;
    transform: translateX(-100%);
}

.closeButton:hover {
    background-color: var(--devPaletteSecondaryDark);
}
