.container {
    background-color: var(--devPaletteBackgroundDefault);
    height: 100%;
    width: 100%;
}

.contentContainer {
    height: fit-content;
    padding: var(--devSpacing2) var(--devSpacing1);
}

.listItemSelected {
    background-color: var(--devPalettePrimaryMain);
    color: var(--devPalettePrimaryContrastText);
    border-radius: 3px;
}

.listItemSelected p {
    color: var(--devPalettePrimaryContrastText);
    opacity: 0.8;
}

.listItemSelected:hover {
    background-color: var(--devPalettePrimaryDark);
}

.optionContainer {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    padding: var(--devSpacing2) var(--devSpacing4);
    height: content-box;
    overflow: auto;
}

.optionContainerTraffic {
    padding: var(--devSpacing2) var(--devSpacing4);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.optionContainerTraffic h4 {
    margin: var(--devSpacing1);
}

.optionContainerTraffic > div {
    margin: 4px;
}

.targetOptionContainer {
    padding: var(--devSpacing2) var(--devSpacing4);
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    box-shadow: var(--devShadows15);
    border-radius: var(--devSpacing05);
    background-color: var(--devPaletteBackgroundSemiTransparentPaper);
}

.startButton {
    position: absolute;
    right: var(--devSpacing2);
    top: var(--devSpacing2);
    border-radius: 100px;
    z-index: 4;
}

.gunIconContainer {
    position: relative;
}

.gunIconContainer span {
    position: absolute;
    background-color: var(--devPaletteSecondaryMain);
    left: 0;
    z-index: -1;
    border-radius: 100px;
}

.gunIcon {
    padding: var(--devSpacing1);
    height: 100%;
    width: 100%;
}
