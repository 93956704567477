.paper {
    text-align: center;
    color: var(--devPaletteTextSecondary);
}

.container {
    width: 80px;
    margin: var(--devSpacing2);
    flex-shrink: 0;
}

.gridContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
