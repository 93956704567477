.toolbox {
    overflow: hidden;
}

.dePaper {
    border: none;
    box-shadow: none;
}

.toolbox .handle {
    width: 100%;
    background: #5b9bd5;
    color: #fff;
    text-align: left;
    padding: 1px 5px;
    opacity: 0.8;
    user-select: none;
    overflow: hidden;
}

.toolbox .handle .buttons {
    float: right;
}

.toolbox .handle.editable {
    cursor: pointer;
    opacity: 1;
}

.spectatorControlsContainer {
    height: 100%;
    text-align: start;
}

.spectatorControls {
    height: 100%;
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: start;
}

.spectatorControlsContainer h6 {
    padding: var(--devSpacing1) var(--devSpacing2);
}

.spectatorTimer {
    width: 100%;
}

.headerIcon {
    margin: 16px 8px;
}
