.backendOffline {
    color: var(--devPaletteWarningMain);
}

.deviceConnected {
    color: green;
}

.deviceDisconnected {
    color: var(--devPaletteErrorMain);
}

.errorIcon {
    background-color: var(--devPaletteErrorLight);
    height: 24px;
    width: 24px;
    color: var(--devPaletteErrorContrastText);
}

.resetContainer {
    padding: var(--devSpacing2);
}
