.printDialogContainer {
    max-height: 100%;
    overflow: auto;
}

.optionsContainer {
    margin: 24px;
}

.primaryColor {
    color: var(--devPalettePrimaryMain);
}

.detailsTitle {
    margin: var(--devSpacing2) 0;
}

.targetContainer {
    height: 100%;
}

.optionsContainer h6 {
    margin-bottom: 24px;
}

.previewTargetContainer {
    height: 50%;
    overflow: hidden;
}

.previewContainer {
    height: unset;
}

.previewContainer table {
    margin-bottom: unset;
}

.phaseSection {
    padding: 0 32px 32px 32px;
}

.phaseSection > div {
    height: unset;
}

.phaseSection #table {
    height: unset;
}

@media print {
    .phaseSection {
        height: 100%;
    }

    .previewTarget {
        width: 100%;
        height: 100%;
    }

    .phaseSection table {
        background-color: white;
    }

    .phaseSection th {
        background-color: white;
    }

    .phaseSection h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        /*todo Look in to fixing this in future without important*/
        color: #363636 !important;
    }

    .phaseSection p {
        color: black;
    }

    .detailsContainer {
        color: black;
    }
}

.previewContainer h5 {
    margin: 32px 0 8px;
}

.previewTableContainer {
    height: 50%;
}

.formControl {
    margin: 16px 0 0 0;
}
