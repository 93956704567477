.titleBackground {
    background-color: var(--devPaletteSecondaryMain);
    color: var(--devPaletteSecondaryContrastText);
}

.calendar {
    width: 325px;
    margin: auto;
}

.dayAvailable {
    background-color: var(--devPaletteDivider);
    border-radius: var(--devSpacing5);
}

.moreInfoButton {
    position: absolute;
    right: 0;
    background: linear-gradient(
        100deg,
        rgba(0, 0, 0, 0) 0%,
        var(--devPaletteBackgroundDefault) 50%
    );
}

.sessionMenuButton {
    padding: 4px;
    margin-left: 8px;
}

.sessionCommentButton {
    margin: 0 4px;
}
