.timerMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.timerContainer {
    height: calc(100% - 40px);
}

.circleButton {
    border: 2px solid var(--devPaletteActionActive);
    margin: 0 var(--devSpacing2);
}

:global(.MuiIconButton-colorPrimary).circleButton {
    border-color: var(--devPalettePrimaryMain);
}

:global(.Mui-disabled).circleButton {
    border-color: var(--devPaletteActionDisabled);
}

.circleButton svg {
    font-size: 50px;
}

.statusBar {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
}
