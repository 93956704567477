.tooltipContainer {
    padding: var(--devSpacing2);
    position: relative;
}

.tooltipActions button {
    margin: 0 var(--devSpacing2) 0 0;
}

.spotlightClick {
    background-color: var(--devPaletteSuccessMain);
    content: "";
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    animation: ping 0.8s ease-in-out infinite both;
    border-radius: 4px;
}

@keyframes ping {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
